import { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Skill } from 'classes/skillProfile';
import { SkillProfileContext, SkillProfileContextState } from 'contexts/SkillProfileContext';
import { Benchmark, SkillSuggestionBy, SuggestedSkill, ExtractedSkill } from 'types/types';
import {
  getBenchmarkSkills,
  getCompanySkills,
  getRecommendedSkills,
  getDDNDimensionName,
  getDDNDimensionId,
  skillDetailsEnrichment,
} from 'helpers/SkillAnalysisHelper';
import useCompanyContext from 'helpers/UseCompanyContext';
import Role from 'classes/role';
import SkillsService from 'services/SkillsService';

const useSkillProfileContext = () => {
  const [state, setState] = useContext(SkillProfileContext);
  const { defaultBenchmark, companyPostingsId } = useCompanyContext();
  const [currentSkillsByDescription, setCurrentSkillsByDescription] = useState<string[]>([]);
  // THe generic TT skill level enum has an Unspecified = 0, and we add an "Unspecified" level with value = 0 to the custom skill levels in the vendor table in the database
  const defaultSkillLevel = 0;

  const setRole = (role: Role): void => {
    setState(
      (previousState: SkillProfileContextState): SkillProfileContextState => ({
        ...previousState,
        role,
      })
    );
  };

  const setAddSkillsMode = (mode: SkillSuggestionBy): void => {
    setState(
      (previousState: SkillProfileContextState): SkillProfileContextState => ({
        ...previousState,
        skillsBy: mode,
      })
    );
  };

  const setSkillsFromDescription = (skillsFromDescription: ExtractedSkill[]) => {
    setState(
      (previousState: SkillProfileContextState): SkillProfileContextState => ({
        ...previousState,
        skillsFromDescription,
      })
    );
  };

  const setSkillsFromDescriptionNormalized = (skillsFromDescriptionNormalized: SuggestedSkill[]) => {
    setState(
      (previousState: SkillProfileContextState): SkillProfileContextState => ({
        ...previousState,
        skillsFromDescriptionNormalized,
      })
    );
  };

  const setIsSkillsExtracting = (isSkillsExtracting: boolean) => {
    setState(
      (previousState: SkillProfileContextState): SkillProfileContextState => ({ ...previousState, isSkillsExtracting })
    );
  };

  const setDescriptionOverride = (overrideUpdate: { isOverride: boolean; description: string }): void => {
    setState(
      (previousState: SkillProfileContextState): SkillProfileContextState => ({
        ...previousState,
        descriptionOverride: overrideUpdate,
      })
    );
  };

  const setIsUsingBenchmarkNationwide = (isUsingBenchmarkNationwide: boolean) => {
    setState(
      (previousState: SkillProfileContextState): SkillProfileContextState => ({
        ...previousState,
        isUsingBenchmarkNationwide,
      })
    );
  };

  const fetchCompanySkills = async () => {
    if (!state.totalCompanyPostings || !state.companySkills) {
      const { totalCompanyPostings, skills: companySkills } = await getCompanySkills(companyPostingsId, {
        title: [state?.role?.emsiTitleId],
      });

      setState(
        (previousState: SkillProfileContextState): SkillProfileContextState => ({
          ...previousState,
          totalCompanyPostings,
          companySkills,
        })
      );

      return { totalCompanyPostings, companySkills };
    } else {
      return { totalCompanyPostings: state.totalCompanyPostings, companySkills: state.companySkills };
    }
  };

  const getSkillFrequencyInPostings = async (
    skillId: string,
    companySkillsData: { totalCompanyPostings: number; companySkills: any[] }
  ): Promise<number> => {
    const postingSkill = companySkillsData.companySkills?.find(bs => bs.name === skillId);
    const postingSkillRank = postingSkill ? postingSkill.unique_postings : 0;

    const frequencyInPostings =
      !!companySkillsData.totalCompanyPostings && companySkillsData.totalCompanyPostings > 0
        ? postingSkillRank / companySkillsData.totalCompanyPostings
        : 0;
    return Math.round(frequencyInPostings * 100);
  };

  const addSkillsGrowthToSuggestedSkills = async (suggestedSkills: SuggestedSkill[]): Promise<SuggestedSkill[]> => {
    const skillIds = suggestedSkills.map(skill => skill.skillId);
    const skillsGrowthResponses = await SkillsService.getProjectedSkillsGrowth(skillIds);
    return suggestedSkills.map(suggestedSkill => ({
      ...suggestedSkill,
      growthPercent: skillsGrowthResponses?.find(sgr => sgr.id === suggestedSkill.skillId)?.growthPercent.mid || 0,
      growthCategory: skillsGrowthResponses?.find(sgr => sgr.id === suggestedSkill.skillId)?.growthCategory || 'Stable',
    }));
  };

  const addSalaryWithSkillToSuggestedSkills = async (suggestedSkills: SuggestedSkill[]): Promise<SuggestedSkill[]> => {
    if (!state.role) return suggestedSkills;
    const skillSalaries = await SkillsService.getSkillsSalaries(state.role.emsiTitleId);
    return suggestedSkills.map(suggestedSkill => ({
      ...suggestedSkill,
      salaryBySkill: skillSalaries?.ranking.buckets.find(bucket => bucket.name === suggestedSkill.skillId)
        ?.median_salary,
    }));
  };

  const setSuggestedSkills = async (suggestedSkills: SuggestedSkill[], skillsBy: SkillSuggestionBy): Promise<void> => {
    const enrichedSuggestedSkills = await skillDetailsEnrichment(suggestedSkills);
    const suggestedSkillsWithSkillsGrowth = await addSkillsGrowthToSuggestedSkills(enrichedSuggestedSkills);
    const suggestedSkillsWithSalaryBySkill = await addSalaryWithSkillToSuggestedSkills(suggestedSkillsWithSkillsGrowth);

    setState((previousState: SkillProfileContextState): SkillProfileContextState => {
      const currentSkills = previousState.suggestedSkills || [];
      const currentSelectedSkillIds = previousState.tempRoleSkills?.map(skill => skill.skillId) || [];
      const currentSkillsBySkillIds = new Set(
        currentSkills.filter(skills => skills.suggestedBy === skillsBy).map(skills => skills.skillId)
      );
      const mergedSkills = [
        ...currentSkills,
        ...suggestedSkillsWithSalaryBySkill
          .filter(skill => !currentSkillsBySkillIds.has(skill.skillId))
          .map(skill => {
            return {
              ...skill,
              isSelected: currentSelectedSkillIds.includes(skill.skillId),
            };
          }),
      ];

      return {
        ...previousState,
        isLoadingSuggestedSkills: {
          ...previousState.isLoadingSuggestedSkills,
          [skillsBy]: false,
        },
        suggestedSkills: mergedSkills,
      };
    });
  };

  // Convert extracted skills to common data type
  useEffect(() => {
    if (state.skillsFromDescription !== undefined && !state.isSkillsExtracting) {
      const extractedSkills: SuggestedSkill[] = [];

      state.skillsFromDescription.forEach((skill: ExtractedSkill) => {
        const previousSelection = state.suggestedSkills?.find(
          s => s.skillId === skill.id && s.suggestedBy === SkillSuggestionBy.JobDescription
        );

        extractedSkills.push({
          skillId: skill.id,
          skillName: skill.name,
          isSelected: previousSelection?.isSelected || false,
          isManual: previousSelection?.isManual || false,
          percentPostings: skill.percentPostings,
          suggestedBy: SkillSuggestionBy.JobDescription,
        });
      });

      setSkillsFromDescriptionNormalized(extractedSkills);
    }
  }, [state.skillsFromDescription, state.isSkillsExtracting]);

  const suggestedSkillsByType = (suggestedBy: SkillSuggestionBy, suggestedSkills?: SuggestedSkill[]) => {
    return suggestedSkills?.filter(skill => skill.suggestedBy !== suggestedBy);
  };

  const loadJobDescriptionSkills = async (): Promise<void> => {
    const normalizedSkills: string[] =
      state.skillsFromDescriptionNormalized && state.skillsFromDescriptionNormalized.length > 0
        ? state.skillsFromDescriptionNormalized?.map(skill => {
            return skill.skillId;
          })
        : [];

    if (JSON.stringify(normalizedSkills) !== JSON.stringify(currentSkillsByDescription)) {
      setState(
        (previousState: SkillProfileContextState): SkillProfileContextState => ({
          ...previousState,
          isLoadingSuggestedSkills: {
            ...previousState.isLoadingSuggestedSkills,
            [SkillSuggestionBy.JobDescription]: true,
          },
          suggestedSkills: suggestedSkillsByType(SkillSuggestionBy.JobDescription, previousState.suggestedSkills),
        })
      );
      const companySkillsData: { totalCompanyPostings: number; companySkills: any[] } = await fetchCompanySkills();
      const skillsByDescriptionPromises = state.skillsFromDescriptionNormalized?.map(
        async (skill): Promise<SuggestedSkill> => {
          const frequencyInPostings = await getSkillFrequencyInPostings(skill.skillId, companySkillsData);
          const frequencyInProfiles = 0;

          return {
            ...skill,
            frequencyInPostings,
            frequencyInProfiles,
          };
        }
      );

      const skillsByDescription = await Promise.all(skillsByDescriptionPromises || []);

      await setSuggestedSkills(skillsByDescription, SkillSuggestionBy.JobDescription);
      setCurrentSkillsByDescription(normalizedSkills);

      setState(
        (previousState: SkillProfileContextState): SkillProfileContextState => ({
          ...previousState,
          isLoadingSuggestedSkills: {
            ...previousState.isLoadingSuggestedSkills,
            [SkillSuggestionBy.JobDescription]: false,
          },
        })
      );
    }
  };

  const loadBenchmarkSkills = async (benchmark?: Benchmark): Promise<void> => {
    const benchmarkToUse = benchmark || (defaultBenchmark as Benchmark);

    if (!benchmarkToUse) {
      setState((prevState: SkillProfileContextState) => ({
        ...prevState,
        isLoadingSuggestedSkills: {
          ...prevState.isLoadingSuggestedSkills,
          [SkillSuggestionBy.Benchmark]: false,
        },
      }));
      return;
    }

    setState((prevState: SkillProfileContextState) => ({
      ...prevState,
      isLoadingSuggestedSkills: {
        ...prevState.isLoadingSuggestedSkills,
        [SkillSuggestionBy.Benchmark]: true,
      },
      suggestedSkills: suggestedSkillsByType(SkillSuggestionBy.Benchmark, prevState.suggestedSkills),
      benchmark: benchmarkToUse,
    }));

    const rootContext = {
      company: benchmarkToUse.companies?.map(l => l.id),
      msa: benchmarkToUse.locations?.map(c => c.id),
      naics6: benchmarkToUse.industries?.map(i => i.id),
    };

    const titleContext = {
      ...rootContext,
      title: state.role?.emsiTitle?.id ? [state.role?.emsiTitle?.id] : [undefined],
    };

    let benchmarkSkills: { id: string; total: number; name: string; rank: number }[] = [];
    let isUsingBenchmarkNationwide = false;

    const { totalBenchmarkPostings, skills: benchmarkSkillsByTitle } = await getBenchmarkSkills(titleContext);

    if (benchmarkSkillsByTitle.length > 0) {
      ReactGA.event({
        category: 'add skills',
        action: 'benchmark skills',
        label: `${benchmarkSkillsByTitle.length} skills`,
      });
      benchmarkSkills = benchmarkSkillsByTitle.map(skill => ({ ...skill, total: totalBenchmarkPostings }));
    } else {
      ReactGA.event({
        category: 'add skills',
        action: 'benchmark skills',
        label: `${benchmarkSkillsByTitle.length} skills (nationwide)`,
      });
      isUsingBenchmarkNationwide = true;
      const nationwideContext = {
        title: state.role?.emsiTitle?.id ? [state.role?.emsiTitle?.id] : [undefined],
      };
      const { totalBenchmarkPostings: totalNationwidePostings, skills: nationwideSkillsByTitle } =
        await getBenchmarkSkills(nationwideContext);
      benchmarkSkills = nationwideSkillsByTitle.map(skill => ({ ...skill, total: totalNationwidePostings }));
    }
    const companySkillsData: { totalCompanyPostings: number; companySkills: any[] } = await fetchCompanySkills();
    const skillsByBenchmarkPromises = benchmarkSkills.map(async (skill): Promise<SuggestedSkill> => {
      const frequencyInPostings = await getSkillFrequencyInPostings(skill.id, companySkillsData);
      const benchmarkSkillRank = benchmarkSkills.find(bs => bs.id === skill.id)?.rank || 0;
      let frequencyInBenchmark = benchmarkSkillRank / skill.total;
      frequencyInBenchmark = Math.round(frequencyInBenchmark * 100);
      return {
        ...skill,
        skillId: skill.id,
        skillName: skill.name,
        suggestedBy: SkillSuggestionBy.Benchmark,
        frequencyInBenchmark,
        frequencyInPostings,
      };
    });
    const skillsWithData: SuggestedSkill[] = await Promise.all(skillsByBenchmarkPromises);
    await setSuggestedSkills(skillsWithData, SkillSuggestionBy.Benchmark);
    setIsUsingBenchmarkNationwide(isUsingBenchmarkNationwide);
  };

  const loadRecommendedSkills = async (): Promise<void> => {
    setState(
      (previousState: SkillProfileContextState): SkillProfileContextState => ({
        ...previousState,
        isLoadingSuggestedSkills: {
          ...previousState.isLoadingSuggestedSkills,
          [SkillSuggestionBy.Recommended]: true,
        },
        suggestedSkills: suggestedSkillsByType(SkillSuggestionBy.Recommended, previousState.suggestedSkills),
      })
    );

    const dimensionName = getDDNDimensionName(state?.role);
    const dimensionId = getDDNDimensionId(state?.role);

    const recommendedSkills =
      dimensionId && dimensionName
        ? (await getRecommendedSkills(dimensionName, dimensionId)).map(skill => {
            return {
              ...skill,
              suggestedBy: SkillSuggestionBy.Recommended,
            };
          })
        : [];
    await setSuggestedSkills(recommendedSkills, SkillSuggestionBy.Recommended);
  };

  const selectTempSuggestedSkill = (suggestedSkill: Skill | SuggestedSkill): void => {
    suggestedSkill = {
      skillLevel: defaultSkillLevel,
      ...suggestedSkill,
    };

    setState((previousState: SkillProfileContextState): SkillProfileContextState => {
      // check if suggested skill is already selected
      const newSuggestedSkill = previousState.tempRoleSkills?.some(skill => skill.skillId === suggestedSkill.skillId)
        ? []
        : [suggestedSkill];

      const updatedTempRoleSkills = [...(previousState.tempRoleSkills || []), ...newSuggestedSkill];

      return {
        ...previousState,
        tempRoleSkills: updatedTempRoleSkills,
        suggestedSkills: previousState.suggestedSkills?.map(skill => ({
          ...skill,
          isSelected: skill.skillId === suggestedSkill.skillId ? true : skill.isSelected,
        })),
      };
    });
  };

  const deselectTempSuggestedSkill = (suggestedSkill: Skill | SuggestedSkill): void => {
    setState(
      (previousState: SkillProfileContextState): SkillProfileContextState => ({
        ...previousState,
        tempRoleSkills: previousState.tempRoleSkills?.filter(skill => skill.skillId !== suggestedSkill.skillId),
        suggestedSkills: previousState.suggestedSkills?.map(skill => {
          return {
            ...skill,
            isSelected: skill.skillId === suggestedSkill.skillId ? false : skill.isSelected,
          };
        }),
      })
    );
  };

  const setSkillTypeFilter = (skillTypeId: string): void => {
    setState(
      (previousState: SkillProfileContextState): SkillProfileContextState => ({
        ...previousState,
        skillTypeFilter: state.SkillTypes.find(type => type.id === skillTypeId) || state.SkillTypes[0],
      })
    );
  };

  const setActiveSortBy = (id: string): void => {
    const activeSortBy = state.sortBy.find(type => type.id === id) || state.sortBy[0];
    setState(
      (previousState: SkillProfileContextState): SkillProfileContextState => ({
        ...previousState,
        activeSortBy,
      })
    );
  };

  return {
    previousState: state.previousState,
    role: state.role,
    loadingSkillProfile: state.isLoadingSkillProfile,
    roleSkills: state.roleSkills,
    skillsBy: state.skillsBy,
    suggestedSkills: state.suggestedSkills,
    loadingSuggestedSkills: state.isLoadingSuggestedSkills,
    tempRoleSkills: state.tempRoleSkills,
    benchmark: state.benchmark,
    setRole,
    setAddSkillsMode,
    setSuggestedSkills,
    loadJobDescriptionSkills,
    loadBenchmarkSkills,
    loadRecommendedSkills,
    selectTempSuggestedSkill,
    deselectTempSuggestedSkill,
    setSkillsFromDescription,
    isUsingBenchmarkNationwide: state.isUsingBenchmarkNationwide,
    updatedDescription: state.updatedDescription,
    skillsFromDescription: state.skillsFromDescription,
    skillsFromDescriptionNormalized: state.skillsFromDescriptionNormalized,
    descriptionOverride: state.descriptionOverride,
    setDescriptionOverride,
    setIsSkillsExtracting,
    isSkillsExtracting: state.isSkillsExtracting,
    skillTypes: state.SkillTypes,
    skillTypeFilter: state.skillTypeFilter,
    setSkillTypeFilter,
    sortBy: state.sortBy,
    activeSortBy: state.activeSortBy,
    setActiveSortBy,
  };
};

export default useSkillProfileContext;
