import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { Section, Column } from 'pages/admin/uiLibrary/UILibraryComponents';
import { BorderColor, IconColorFilter, SpacingSizes } from 'types/types';

const FormControlLabelAlt = styled(FormControlLabel)`
  margin-bottom: ${props => props.theme.customSpacing.px.xxs}px;
  cursor: default;
`;

const BaseIconDemo = (): ReactElement<any> => {
  const theme = useTheme();
  const alertClick = type => {
    alert(`${type} icon clicked`);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Section>
          <Typography variant="strong">Default BaseIcons</Typography>
          <Column>
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Add} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="add"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.ArrowLeft} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="arrow-left"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.ArrowRightLine} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="arrow-right-line"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.ArrowLeftRight} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="arrow-left-right"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Auto} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="auto"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Check} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="check"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.ChevronDown} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="chevron-down"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.ChevronLeft} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="chevron-left"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.ChevronRight} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="chevron-right"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.ChevronUp} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="chevron-up"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Close} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="close"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Copy} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="copy"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Delete} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="delete"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.DiagonalArrowDown} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="diagonal-arrow-down"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.DiagonalArrowUp} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="diagonal-arrow-up"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Download} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="download"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Edit} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="edit"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Export} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="export"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.ExternalLink} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="external-link"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Favorite} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="favorite"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Filter} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="filter"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Location} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="location"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Lock} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="lock"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.LogoEmsiGraySmall} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="logo-emsi-gray-small"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.LogoLightCastIcon} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="logo-lightcast-icon"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon
                  type={IconType.LogoLightCastIcon}
                  marginRight={SpacingSizes.XS}
                  colorFilter={IconColorFilter.Disabled}
                />
              }
              labelPlacement="end"
              label="logo-lightcast-icon (disabled)"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.LogoTT} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="logo-tt"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.LogoTTText} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="logo-tt-text"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Menu} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="menu"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Refresh} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="search"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.SearchGiantInverted} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="search-giant-inverted"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Search} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="refresh"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Upload} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="upload"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.User} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="user"
            />
          </Column>
        </Section>
        <Section>
          <Typography variant="strong">Prop Options</Typography>
          <Column>
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Add} marginRight={SpacingSizes.S} />}
              labelPlacement="end"
              label="type= 'add' (required)"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Add} marginRight={SpacingSizes.S} size="12px" />}
              labelPlacement="end"
              label="size='12px' (default: '16px')"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon type={IconType.Add} marginRight={SpacingSizes.S} onIconClick={() => alertClick('add')} />
              }
              labelPlacement="end"
              label="onIconClick={}"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon type={IconType.Add} marginRight={SpacingSizes.S} colorFilter={IconColorFilter.Warning} />
              }
              labelPlacement="end"
              label="colorFilter='warning' (default: 'default')"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Add} marginRight={SpacingSizes.S} borderColor={BorderColor.Subdued} />}
              labelPlacement="end"
              label="borderColor='subdued' (default: 'none')"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Add} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="marginRight='xs' (default: 'base')"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Add} marginLeft={SpacingSizes.XS} marginRight={SpacingSizes.S} />}
              labelPlacement="end"
              label="marginLeft='xs' (default: 'base')"
            />
          </Column>
        </Section>
        <Section>
          <Typography variant="strong">colorFilter Options</Typography>
          <Column>
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Favorite} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="default"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon
                  type={IconType.Favorite}
                  marginRight={SpacingSizes.XS}
                  colorFilter={IconColorFilter.Subdued}
                />
              }
              labelPlacement="end"
              label="subdued"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon
                  type={IconType.Favorite}
                  marginRight={SpacingSizes.XS}
                  colorFilter={IconColorFilter.Hovered}
                />
              }
              labelPlacement="end"
              label="hovered"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon
                  type={IconType.Favorite}
                  marginRight={SpacingSizes.XS}
                  colorFilter={IconColorFilter.Disabled}
                />
              }
              labelPlacement="end"
              label="disabled"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon
                  type={IconType.Favorite}
                  marginRight={SpacingSizes.XS}
                  colorFilter={IconColorFilter.Information}
                />
              }
              labelPlacement="end"
              label="information"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon
                  type={IconType.Favorite}
                  marginRight={SpacingSizes.XS}
                  colorFilter={IconColorFilter.Success}
                />
              }
              labelPlacement="end"
              label="success"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon
                  type={IconType.Favorite}
                  marginRight={SpacingSizes.XS}
                  colorFilter={IconColorFilter.Warning}
                />
              }
              labelPlacement="end"
              label="warning"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon
                  type={IconType.Favorite}
                  marginRight={SpacingSizes.XS}
                  colorFilter={IconColorFilter.Critical}
                />
              }
              labelPlacement="end"
              label="critical"
            />
            <Button
              variant="contained"
              color="actionPrimary"
              style={{ marginBottom: `${theme.customSpacing.px.xxs}px` }}
            >
              <FormControlLabelAlt
                control={
                  <BaseIcon
                    type={IconType.Favorite}
                    marginRight={SpacingSizes.XXS}
                    colorFilter={IconColorFilter.OnInteractive}
                  />
                }
                labelPlacement="end"
                label="onInteractive"
              />
            </Button>
            <Button
              variant="contained"
              color="actionPrimary"
              style={{ marginBottom: `${theme.customSpacing.px.xxs}px` }}
            >
              <FormControlLabelAlt
                control={
                  <BaseIcon
                    type={IconType.Favorite}
                    marginRight={SpacingSizes.XXS}
                    colorFilter={IconColorFilter.OnPrimary}
                  />
                }
                labelPlacement="end"
                label="onPrimary"
              />
            </Button>
            <Button
              variant="contained"
              color="actionCritical"
              style={{ marginBottom: `${theme.customSpacing.px.xxs}px` }}
            >
              <FormControlLabelAlt
                control={
                  <BaseIcon
                    type={IconType.Favorite}
                    marginRight={SpacingSizes.XXS}
                    colorFilter={IconColorFilter.OnCritical}
                  />
                }
                labelPlacement="end"
                label="onCritical"
              />
            </Button>
          </Column>
        </Section>
        <Divider />
        <Section>
          <Typography variant="strong">borderColor Options</Typography>
          <Column>
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Close} marginRight={SpacingSizes.XS} marginLeft={SpacingSizes.XXS} />}
              labelPlacement="end"
              label="default (none)"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon type={IconType.Close} marginRight={SpacingSizes.XXS} borderColor={BorderColor.Subdued} />
              }
              labelPlacement="end"
              label="subdued"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon type={IconType.Close} marginRight={SpacingSizes.XXS} borderColor={BorderColor.Hovered} />
              }
              labelPlacement="end"
              label="hovered"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon type={IconType.Close} marginRight={SpacingSizes.XXS} borderColor={BorderColor.Disabled} />
              }
              labelPlacement="end"
              label="disabled"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon type={IconType.Close} marginRight={SpacingSizes.XXS} borderColor={BorderColor.Information} />
              }
              labelPlacement="end"
              label="information"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon
                  type={IconType.Close}
                  marginRight={SpacingSizes.XXS}
                  borderColor={BorderColor.InformationSubdued}
                />
              }
              labelPlacement="end"
              label="informationSubdued"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon type={IconType.Close} marginRight={SpacingSizes.XXS} borderColor={BorderColor.Success} />
              }
              labelPlacement="end"
              label="success"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon type={IconType.Close} marginRight={SpacingSizes.XXS} borderColor={BorderColor.Warning} />
              }
              labelPlacement="end"
              label="warning"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon
                  type={IconType.Close}
                  marginRight={SpacingSizes.XXS}
                  borderColor={BorderColor.WarningSubdued}
                />
              }
              labelPlacement="end"
              label="warningSubdued"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon type={IconType.Close} marginRight={SpacingSizes.XXS} borderColor={BorderColor.Critical} />
              }
              labelPlacement="end"
              label="critical"
            />
            <FormControlLabelAlt
              control={
                <BaseIcon
                  type={IconType.Close}
                  marginRight={SpacingSizes.XXS}
                  borderColor={BorderColor.CriticalSubdued}
                />
              }
              labelPlacement="end"
              label="criticalSubdued"
            />
          </Column>
        </Section>
        <Divider />
        <Section>
          <Typography variant="strong">Size Options</Typography>
          <Column>
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Copy} marginRight={SpacingSizes.XXS} size="8px" />}
              labelPlacement="end"
              label="8px"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Copy} marginRight={SpacingSizes.XXS} size="12px" />}
              labelPlacement="end"
              label="12px"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Copy} marginRight={SpacingSizes.XS} />}
              labelPlacement="end"
              label="16px (default)"
            />
            <FormControlLabelAlt
              control={<BaseIcon type={IconType.Copy} marginRight={SpacingSizes.XXS} size="24px" />}
              labelPlacement="end"
              label="24px"
            />
            <Typography variant="caption">Accepts any pixel value</Typography>
          </Column>
        </Section>
      </div>
    </>
  );
};

export default BaseIconDemo;
